import React ,{useRef} from "react";
import { NavLink ,useNavigate} from 'react-router-dom';
import '../css/projects.css'
function Projects() {
    const navigate = useNavigate();
    const handleNavigatenav = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleNavigatenav1 = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleNavigate = () => {
        navigate('/resume');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const handleNavigate3 = () => {
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const goAbout = () => {
        navigate('/about');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
      const sectionRef7 = useRef(null);
      const handleScroll7 = () => {
        if (sectionRef7.current) {
          sectionRef7.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
<div>
    <div className="container-fluid navbar-projects sticky-top bg-white">
<div className='container'>
     <nav class="navbar navbar-expand-lg">
  <div class="container-fluid px-0">
    <a class="navbar-brand navbar-brand-1" href="#">Hurera<br/><span className='navbar-logo1'>Bhalli.</span></a>
    <button class="navbar-toggler navbar-toggler-1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="black" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <desc>Download more icon variants from https://tabler-icons.io/i/menu-2</desc>
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="4" y1="6" x2="20" y2="6" />
  <line x1="4" y1="12" x2="20" y2="12" />
  <line x1="4" y1="18" x2="20" y2="18" />
</svg>

</span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav navbar-nav-1 ms-auto my-2 my-lg-0 navbar-nav-scroll me-lg-5 me-0 gap-lg-4 gap-0" >
        <li class="nav-item mx-auto">
        <NavLink onClick={handleNavigatenav} className={({ isActive }) => (isActive ? 'nav-link active1' : 'nav-link')} to="/">
          Home
          </NavLink>
        </li>
        <li class="nav-item mx-auto">
        <NavLink className={({ isActive }) => (isActive ? 'nav-link active1' : 'nav-link')} to="/projects">
          Projects
          </NavLink>
        </li>
        <li class="nav-item mx-auto">
          <a class="nav-link" href='#' onClick={handleScroll7}>Contact</a>
        </li>
        <li class="nav-item mx-auto">
        <NavLink onClick={handleNavigatenav1} className={({ isActive }) => (isActive ? 'nav-link active1' : 'nav-link')} to="/about">
          About
          </NavLink>
        </li>
      </ul>
      <form class="d-flex justify-content-center" role="search">
        <button class="btn btn-outline-success" type="submit" onClick={handleNavigate}>My Skills</button>
      </form>
    </div>
  </div>
</nav>
</div>
</div>
<div className="container mt-5">
    <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-12">
            <h2 className="project-s1-h1">CodenTire Website Design</h2>
            <p className="project-s1-text1">Website/Landing Page</p>
            <p className="project-s1-text2"><span className="fw-bold">Start Date:</span> 11 May 2022 - <span className="fw-bold">End Date:</span> 24 July 2022</p>
            <div>
                <button className="project-s1-btn1"><img src="assets/eye.svg" className="me-2"/>Total Viewers <span className="ms-3">1,230</span></button>
                <button className="project-s1-btn2 ms-3"><img src="assets/heart.svg" className="me-2"/>210</button>
            </div>
        </div>
        <div className="col-lg-6 col-12 mt-4 mt-lg-0">
            <img src="assets/projectss1.png" className="img-fluid shadow-lg img-project-shdw"/>
        </div>
    </div>
</div>
<div className="container mt-5">
    <div className="row">
        <div className="col-12">
            <p className="project-s2-text">Lorem ipsum dolor sit amet consectetur. Praesent tristique nec nec amet vitae porttitor interdum lacus convallis. Ultrices sapien facilisi faucibus habitasse. Nibh quisque vitae egestas hac. Mauris sed sit dis mauris molestie ultricies vel adipiscing nisl. Pulvinar aliquet in mi massa. Nunc tristique enim id consectetur a posuere convallis porta. Et aliquet congue id neque. Nam eget in eu leo nec. Eu egestas morbi vel auctor neque mi.</p>
            <p className="project-s2-text">Lorem ipsum dolor sit amet consectetur. Praesent tristique nec nec amet vitae porttitor interdum lacus convallis. Ultrices sapien facilisi faucibus habitasse. Nibh quisque vitae egestas hac. Mauris sed sit dis mauris molestie ultricies vel adipiscing nisl. Pulvinar aliquet in mi massa. Nunc tristique enim id consectetur a posuere convallis porta. Et aliquet congue id neque. Nam eget in eu leo nec. Eu egestas morbi vel auctor neque mi.</p>
            <img src="assets/Frame1108.png" className="img-fluid mt-5"/>
            <img src="assets/Frame1108.png" className="img-fluid mt-5"/>
            <img src="assets/Frame1108.png" className="img-fluid mt-5"/>
        </div>
    </div>
</div>
<div className='container'>
      <div className='row mt-5'>
        <div className='col-12 text-center'>
          <p className='pg1-section-1-text s8-text'>FOR YOU</p>
          <h2 className='pg1-section1-heading s8-heading'>Suggested Projects</h2>
        </div>
        </div>
        <div className='row mt-5 mb-5'>
          <div className='col-lg-4 col-md-6 col-12 mt-3 mb-lg-5'>
          <div class="card">
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle6.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
    <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">CodenTire Website Design </h5>
      </div>
      <div className='col-2'>
      <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn'/>
      </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3 mb-lg-5'>
          <div class="card">
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle7.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
    <h5 class="card-title">Edcademy Exam App Design</h5>
    </div>
    <div className='col-2'>
    <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn1'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3 mb-lg-5'>
          <div class="card">
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle8.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Leeway Portal Dashboard Design</h5>
    </div>
    <div className='col-2'>
    <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn2'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
        </div>
        </div>
        <div className='container-fluid pt-md-5 pt-0 footer1_main'>
    <div className='container px-lg-5 pt-lg-5 pt-0 px-md-0'>
      <div className='row footer1-position2 custom-padding-footer1'>
        <div className='col-md-6 col-12 mt-md-5 mt-2  mb-md-5 '>
          <h2 className="footer1-heading">Got a Project! Let’s Talk</h2>
          <p className="footer1-text">Let's Talk and bring your vision to life with a collaborative approach that ensures your project's success.</p>
          <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
            <div className='order-lg-1 order-2 mt-3 mt-lg-0'>
            <a href="https://w.app/hurerabhalli" target="_blank" className='footer1-btn'>
              Contact!
              </a>
            </div>
            <div className='d-flex mt-lg-0 mt-md-1 mt-2 order-lg-2 order-1'>
            <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf1.svg`} className='ms-lg-3'/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf2.svg`} className='ms-3'/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf3.svg`} className='ms-3'/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf4.svg`} className='ms-3'/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf5.svg`} className='ms-3'/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf6.svg`} className='ms-3'/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf7.svg`} className='ms-3'/>
      </a>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-12 footer1-position1 d-flex justify-content-center'>
        <img src={`${process.env.PUBLIC_URL}/assets/Hurerabhai.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1position.png`} className='img-fluid footer1-position3'/>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1positon2.png`} className='img-fluid footer1-position4' />
  </div>
  <div className='container-fluid footer2-bg' ref={sectionRef7}>
    <div className='container px-5'>
    <div className='row px-lg-5 pt-5'>
      <div className='col-md-6 col-12 order-md-1 order-2 mt-md-0 mt-5 mb-md-0 mb-5'>
        <div className='d-flex align-items-center'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img1.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Call:</p>
          <p className='m-0 footer2-text2'>+92-318-7842650</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img2.svg`}className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>E-mail:</p>
          <p className='m-0 footer2-text2'>hurerabhalli@gmail.com</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img3.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Location:</p>
          <p className='m-0 footer2-text2'>Johar Town, Lahore Pakistan</p>
        </div>
        </div>
      </div>
      <div className='col-md-6 col-12 order-md-2 order-1'>
        <div>
        <div className='ms-md-5 ms-0'>
        <h3 className='footer2-heading1'>Contact me:</h3>
        <p className='footer2-text3'>Let’s make something new, different and more meaningful or make thing more visual or conceptual!</p>
        <div>
          <button className='footer2-btn1'>My Projects</button>
          <button className='footer2-btn2 ms-sm-3 ms-1' onClick={handleNavigate}>My Skills</button>
        </div>
        </div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2laptop.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div className='container-fluid footer3-bg'>
      <footer class="footer">
<div class="pt-5">
  <div class="container overflow-hidden">
    <div class="row align-items-sm-center">
      <div class="col-12 order-0 order-xl-0">
        <div class="footer-logo-wrapper text-center text-sm-start">
      <h2 className='text-center footer3-heading'>Hurera Bhalli<span className='footer3-mainheading'>.</span></h2>
      <p className='text-center  mx-auto footer3-text'>I work with passion of taking new challenges and creativity new ones in advertising sector. </p>
    <div className='d-flex justify-content-center gap-3'>
    <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social1.svg`}/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social2.svg`}/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social3.svg`}/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social4.svg`}/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social5.svg`}/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social6.svg`}/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social7.svg`}/>
      </a>
    </div>
        </div>
      </div>

      <div class="col-12 order-2 pt-3 pb-3">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleNavigate3}>Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={handleNavigate}>My Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" >Projects</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={handleScroll7}>Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={goAbout}>About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleNavigate}>Why Hire me?</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="py-3 border-top border-light-subtle w-75 mx-auto">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="footer-copyright-wrapper text-center">
        <p className='p-0 m-0'>Copyrights reserved @ hurera bhalli 2024</p>
        </div>
      </div>
    </div>
  </div>
</div>

</footer>
      </div>
</div>
  );
}

export default Projects;
