import React, { useState,useRef ,useEffect} from 'react';
import '../css/homePage.css'
import { NavLink ,useNavigate, } from 'react-router-dom';
import { Navigation,Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const HomePage = () => {
  const sectionRef = useRef(null);
  const sectionRef3 = useRef(null);
  const sectionRef4 = useRef(null);
  const sectionRef5 = useRef(null);
  const swiperRef = useRef(null);
  const swiperRef1 = useRef(null);

  useEffect(() => {
    const swiper = swiperRef1.current.swiper;

    const handleReachEnd = () => {
      swiper.autoplay.stop();
      swiper.off('reachEnd', handleReachEnd);
      setTimeout(() => {
        swiper.slideTo(0);
      }, 5000); 
    };

    swiper.on('reachEnd', handleReachEnd);

    return () => {
      swiper.off('reachEnd', handleReachEnd);
    };
  }, []);
  useEffect(() => {
    const swiper = swiperRef.current.swiper;

    const handleReachEnd = () => {
      swiper.autoplay.stop();
      swiper.off('reachEnd', handleReachEnd);
      setTimeout(() => {
        swiper.slideTo(0);
      }, 5000); 
    };

    swiper.on('reachEnd', handleReachEnd);

    return () => {
      swiper.off('reachEnd', handleReachEnd);
    };
  }, []);
  const handleScroll5 = () => {
    if (sectionRef5.current) {
      sectionRef5.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll3 = () => {
    if (sectionRef3.current) {
      sectionRef3.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll4 = () => {
    if (sectionRef4.current) {
      sectionRef4.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const navigate = useNavigate();
const navigateTOProjects =()=>{
  navigate('/projects');
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

  const handleNavigate = () => {
    navigate('/resume');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleNavigate5 = () => {
    navigate('/about');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const  handleNavigatenav = () => {
    // navigate('/about');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div>
    <div className='hero-section-bg '>
    <div className='container'>
     <nav class="navbar navbar-expand-lg">
  <div class="container-fluid px-0">
    <a class="navbar-brand" href="#">Hurera<br/><span className='navbar-logo1'>Bhalli.</span></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#00E000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <desc>Download more icon variants from https://tabler-icons.io/i/menu-2</desc>
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="4" y1="6" x2="20" y2="6" />
  <line x1="4" y1="12" x2="20" y2="12" />
  <line x1="4" y1="18" x2="20" y2="18" />
</svg>

</span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll me-lg-5 me-0 gap-lg-4 gap-0" >
        <li class="nav-item mx-auto">
          <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/">
          Home
          </NavLink>
        </li>
        <li class="nav-item mx-auto">
          <a className='nav-link' onClick={handleScroll4}>Projects</a>
        </li>
        <li class="nav-item mx-auto">
          <a class="nav-link" onClick={handleScroll5}>Contact</a>
        </li>
        <li class="nav-item mx-auto">
        <NavLink onClick={handleNavigatenav} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/about">
          About
          </NavLink>
        </li>
      </ul>
      <form class="d-flex justify-content-center" role="search">
        <button class="btn btn-outline-success" type="submit" onClick={handleNavigate}>My Skills</button>
      </form>
    </div>
  </div>
</nav>
</div>
<div className='container pt-5'>
  <div className='row'>
    <div className='col-lg-6 col-12'>
      <h1 className='hero-section-heading'>Hello, I’m<br/>Hurera Bhalli An<br/>UI UX & Graphics Designer</h1>
      <p className='hero-section-text'>Experienced UI/UX designer specializing in web and mobile app interfaces with four years of professional expertise. Passionate about crafting designs that blend aesthetics with user experience.</p>
<div className='mt-2 d-flex gap-3'>
  <button className='hero-section-btn1' onClick={handleScroll3}>Hire Me!</button>
  <button className='hero-section-btn2' onClick={handleScroll4}><img src={`${process.env.PUBLIC_URL}/assets/Vector.png`} className='me-2'/>My Projects?</button>
</div>
    </div>
    <div className='col-lg-6 col-12 mt-lg-0 mt-5  hero-section-position'>
      <img src={`${process.env.PUBLIC_URL}/assets/heroSectionImg.png`} className='img-fluid pt-5 ps-5'/>
      <img src={`${process.env.PUBLIC_URL}/assets/herop1.png`} className='hero-img1-position' width='73' height='77' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop2.png`} className='hero-img2-position' width='113' height='98' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop3.png`} className='hero-img3-position' width='70' height='68' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop4.png`} className='hero-img4-position' width='150' height='129' />
    </div>
  </div>
  <div className='row mt-5 pb-5'>
    <div className='col-lg-5 col-12 d-flex gap-3 align-items-center justify-content-lg-start justify-content-center'>
      <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social1.svg`}/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social2.svg`}/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social3.svg`}/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social4.svg`}/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social5.svg`}/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social6.svg`}/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social7.svg`}/>
      </a>
    </div>
    <div className='col-lg-7 col-12 mt-lg-0 mt-4 d-flex justify-content-lg-start justify-content-center'>
      <button className='hero-section-swiper' onClick={handleScroll}>Swipe Down<img src={`${process.env.PUBLIC_URL}/assets/swiper.svg`} className='ms-2'/></button>
    </div>
  </div>
</div>
<img src={`${process.env.PUBLIC_URL}/assets/navbarimg.png`} className='custom-img-position-heroSection'/>
<img src={`${process.env.PUBLIC_URL}/assets/navbarimg.png`} className='custom-img2-position-heroSection'/>
    </div>
    <div className='container pg1-slider-1' ref={sectionRef}>
      <div className='row mt-5'>
        <div className='col-12 text-center'>
          <p className='pg1-section-1-text'>SERVICES</p>
          <h2 className='pg1-section1-heading'>Expertise Services! Let’s check it out</h2>
          <p className='pg1-section1-text2 px-md-5 px-0'>Let's check it out. Offering a range of specialized design solutions that cater to your unique needs and elevate your digital presence.</p>
        </div>
        <div className='col-12 mt-3'>
        <Swiper
        ref={swiperRef }
      modules={[Navigation, Autoplay]}
      spaceBetween={20}
      navigation
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        500: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      }}      
    >
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card1pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>UI/UX</h4>
          <p className='px-2'>Crafting intuitive interfaces that seamlessly blend user-centric design principles with captivating UX.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card2pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>Graphics Design</h4>
          <p className='px-2'>Transforming concepts into visually striking designs that convey messages effectively and leave a lasting impression.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card3pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>Web Design</h4>
          <p className='px-2'>Creating visually responsive websites that merge aesthetics with seamless functionality for an optimal user experience.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card4pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>App Design</h4>
          <p className='px-2'>Designing user-friendly mobile applications that prioritize usability, intuitive navigation, and visually engaging interfaces.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card5pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>LinkedIn PDF’s</h4>
          <p className='px-2'>Crafting visually impressive PDFs for LinkedIn profiles that showcase professional expertise and leave a lasting impression.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card6pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>Freepik Assets</h4>
          <p className='px-2'>Creating high-quality vector assets for Freepik that enrich creative projects and inspire designers worldwide.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card7pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>Web Apps Design</h4>
          <p className='px-2'>Developing sleek and user-friendly web applications with a focus on intuitive interfaces and smooth interactions.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card text-center'>
          <img src={`${process.env.PUBLIC_URL}/assets/card8pg1.png`} className='mt-2'/>
          <h4 className='mt-3'>Dashboard Design</h4>
          <p className='px-2'>Designing visually appealing and user-friendly dashboards that provide actionable insights and streamline data management.</p>
        </div>
      </SwiperSlide>
    </Swiper>
        </div>
      </div>
      
    </div>
    <div className='container-fluid pg1-s3-bg' ref={sectionRef3}>
      <div className='row s3-pg1-hide'>
        <div className='col-6 s3-position-img'>
          <div className='row d-flex justify-content-end pg1-s3-margin s3-position-img1'>
            <div className='col-8 ps-2 pe-2'>
            <img src={`${process.env.PUBLIC_URL}/assets/new-landing-hero-image1.png`} height="450"/>
            </div>
          </div>
        </div>
        <div className='col-6 pt-5 pb-5'>
          <div className='row'>
            <div className='col-10'>
          <h4 className='pg1-section-1-text'>ABOUT ME</h4>
          <h2 className='pg1-section1-heading'>Designing Solutions, Not Just Visuals!</h2>
          <p className='pg1-section1-text3'>Not Just Visuals! Focusing on creating designs that solve problems, enhance user experiences, and deliver tangible results for businesses and their target audiences.</p>
            <div className='row s3-custom-bg-pg1'>
              <div className='col-1 '>
              <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`} />
              </div>
              <div className='col-11'>
                <h4>99% Client Satisfaction!</h4>
                <p className='mx-0 my-0'>Delivering exceptional design solutions that exceed expectations and leave clients delighted.</p>
              </div>
            </div>
            <div className='row s3-custom-bg2-pg1 mt-2 mb-3'>
              <div className='col-1 '>
              <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`} />
              </div>
              <div className='col-11'>
                <h4>4+ Years of Experience!</h4>
                <p className='mx-0 my-0'>Leveraging my expertise and knowledge gained over the years to deliver outstanding design solutions that meet client goals and drive success.</p>
              </div>
          </div>
          <button className='pg1-s3-btn' onClick={handleNavigate}>View My Skills<img src={`${process.env.PUBLIC_URL}/assets/Arrow1.svg`} className='ms-2'/></button>
            </div>
          </div>
        </div>
      </div>
      <div className='row d-lg-none d-block pt-5 pb-5 ps-md-5 pe-md-5 ps-2 pe-2'>
      <div className='col-12'>
          <h4 className='pg1-section-1-text'>ABOUT ME</h4>
          <h2 className='pg1-section1-heading'>Designing Solutions, Not Just Visuals!</h2>
          <p className='pg1-section1-text3'>Not Just Visuals! Focusing on creating designs that solve problems, enhance user experiences, and deliver tangible results for businesses and their target audiences.</p>
            <div className='row s3-custom-bg-pg1'>
              <div className='col-sm-1 col-2'>
              <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`} />
              </div>
              <div className='col-sm-11 col-10'>
                <h4>99% Client Satisfaction!</h4>
                <p className='mx-0 my-0'>Delivering exceptional design solutions that exceed expectations and leave clients delighted.</p>
              </div>
            </div>
            <div className='row s3-custom-bg2-pg1 mt-2 mb-3'>
              <div className='col-sm-1 col-2'>
              <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`} />
              </div>
              <div className='col-sm-11 col-10'>
                <h4>4+ Years of Experience!</h4>
                <p className='mx-0 my-0'>Leveraging my expertise and knowledge gained over the years to deliver outstanding design solutions that meet client goals and drive success.</p>
              </div>
          </div>
          <button className='pg1-s3-btn' onClick={handleNavigate}>View My Skills<img src={`${process.env.PUBLIC_URL}/assets/Arrow1.svg`} className='ms-2'/></button>
        </div>
            <div className='col-12 px-sm-5 px-0 mt-5'>
            <img src={`${process.env.PUBLIC_URL}/assets/new-landing-hero-image1.png`} className="img-fluid px-5"/>
        </div>
      </div>
    </div>
    <div className='pg1-s5-bg'>
    <div className='container-fluid pg1-s4-bg'>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-12'>
            <h4 className='mt-5 pg1-section-4-text'>PROCESS</h4>
            <h2 className='pg1-section4-heading'>My Working Process</h2>
          </div>
        </div>
        <div className='row mt-5 pb-4'>
          <div className='col-lg-3 col-6 text-center s4-custom-position'>
            <img src={`${process.env.PUBLIC_URL}/assets/s4img1.png`} className='img-s4-custom'/>
          <p className='pg1-s4-text1 mt-4'>Discover</p>
          <img src={`${process.env.PUBLIC_URL}/assets/s4img2.png`} className='img-fluid s4-custom-position1'/>
          </div>
          <div className='col-lg-3 col-6 text-center s4-custom-position'>
            <img src={`${process.env.PUBLIC_URL}/assets/s4img3.png`} className='img-s4-custom'/>
          <p className='pg1-s4-text1 mt-4'>Define</p>
          <img src={`${process.env.PUBLIC_URL}/assets/s4img2.png`} className='img-fluid s4-custom-position1 s4-img-hide'/>
          </div>
          <div className='col-lg-3 col-6 text-center s4-custom-position mt-lg-0 mt-5'>
            <img src={`${process.env.PUBLIC_URL}/assets/s4img4.png`} className='img-s4-custom'/>
          <p className='pg1-s4-text1 mt-4'>Ideate</p>
          <img src={`${process.env.PUBLIC_URL}/assets/s4img2.png`} className='img-fluid s4-custom-position1'/>
          </div>
          <div className='col-lg-3 col-6 text-center s4-custom-position mt-lg-0 mt-5'>
            <img src={`${process.env.PUBLIC_URL}/assets/s4img5.png`} className='img-s4-custom'/>
          <p className='pg1-s4-text1 mt-4'>Design</p>
          </div>
        </div>
      </div>
    </div>
    <div className='container-fluid'>
    <div className='container pg1-slider-2'>
      <div className='row mt-2'>
        <div className='col-12 text-center'>
          <p className='pg1-section-1-text mt-5'>TESTIMONIALS</p>
          <h2 className='pg1-section1-heading'>What People Say’s About Me!</h2>
        </div>
        <div className='col-12 mt-3'>
        <Swiper
        ref={swiperRef1}
            modules={[Navigation, Autoplay]}
            spaceBetween={20}
            navigation
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
            }}
            slidesPerGroup={1}
      breakpoints={{
        320: {
          slidesPerView: 1,
        },
        500: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      }}
    >
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card2 text-start'>
          <div className='mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`} className='ms-4'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          </div>
          <div className='d-flex align-items-center mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/s5img1.png`} className='mt-2'/>
            <h5 className='ms-2 card2-heading'>Peter James</h5>
          </div>
          <p className='mt-2 card2-text'>I’d the pleasure of working with <span className='fw-bold'>Hurera Bhalli</span> for our website <span className='fw-bold'>UI/UX design</span>, and, I couldn't be that much happier with the results. From the initial consultation to the final delivery, the experience was exceptional.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card2 text-start'>
          <div className='mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`} className='ms-4'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          </div>
          <div className='d-flex align-items-center mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/s5img1.png`} className='mt-2'/>
            <h5 className='ms-2 card2-heading'>Peter James</h5>
          </div>
          <p className='mt-2 card2-text'>I’d the pleasure of working with <span className='fw-bold'>Hurera Bhalli</span> for our website <span className='fw-bold'>UI/UX design</span>, and, I couldn't be that much happier with the results. From the initial consultation to the final delivery, the experience was exceptional.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card2 text-start'>
          <div className='mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`} className='ms-4'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          </div>
          <div className='d-flex align-items-center mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/s5img1.png`} className='mt-2'/>
            <h5 className='ms-2 card2-heading'>Peter James</h5>
          </div>
          <p className='mt-2 card2-text'>I’d the pleasure of working with <span className='fw-bold'>Hurera Bhalli</span> for our website <span className='fw-bold'>UI/UX design</span>, and, I couldn't be that much happier with the results. From the initial consultation to the final delivery, the experience was exceptional.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card2 text-start'>
          <div className='mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`} className='ms-4'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          </div>
          <div className='d-flex align-items-center mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/s5img1.png`} className='mt-2'/>
            <h5 className='ms-2 card2-heading'>Peter James</h5>
          </div>
          <p className='mt-2 card2-text'>I’d the pleasure of working with <span className='fw-bold'>Hurera Bhalli</span> for our website <span className='fw-bold'>UI/UX design</span>, and, I couldn't be that much happier with the results. From the initial consultation to the final delivery, the experience was exceptional.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card2 text-start'>
          <div className='mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`} className='ms-4'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          </div>
          <div className='d-flex align-items-center mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/s5img1.png`} className='mt-2'/>
            <h5 className='ms-2 card2-heading'>Peter James</h5>
          </div>
          <p className='mt-2 card2-text'>I’d the pleasure of working with <span className='fw-bold'>Hurera Bhalli</span> for our website <span className='fw-bold'>UI/UX design</span>, and, I couldn't be that much happier with the results. From the initial consultation to the final delivery, the experience was exceptional.</p>
        </div>
      </SwiperSlide>
      <SwiperSlide className='d-flex justify-content-center'>
        <div className='pg1-card2 text-start'>
          <div className='mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`} className='ms-4'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          <img src={`${process.env.PUBLIC_URL}/assets/Star1.svg`}className='ms-2'/>
          </div>
          <div className='d-flex align-items-center mt-2'>
          <img src={`${process.env.PUBLIC_URL}/assets/s5img1.png`} className='mt-2'/>
            <h5 className='ms-2 card2-heading'>Peter James</h5>
          </div>
          <p className='mt-2 card2-text'>I’d the pleasure of working with <span className='fw-bold'>Hurera Bhalli</span> for our website <span className='fw-bold'>UI/UX design</span>, and, I couldn't be that much happier with the results. From the initial consultation to the final delivery, the experience was exceptional.</p>
        </div>
      </SwiperSlide>
    </Swiper>
        </div>
      </div>
      
    </div>
    </div>
    </div>
    <div className='container-fluid pg1-s6-bg'>
    </div>
    <div className='container-fluid pg1-s7-bg'>
      <div className='container'>
        <div className='row d-flex justify-content-center'>
          <div className='col-lg-10 col-12'>
            <div className='inner-section-bg-s7 s7-position-4'>
              <div className='row'>
                <div className='col-md-7 col-12 mt-5 mb-5 ps-md-5 ps-4 padding-custom-s7'>
                  <h2>Distinctive UI Excellence!</h2>
                  <p><span className='fw-bold'>Unlock the Power of Exceptional UI Design:</span> Stand Out and Captivate with Unique and Innovative Interfaces.</p>
                  <div className='d-flex align-items-center'>
                    <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`}/>
                    <h5 className='mx-4 my-0'>Continuous Learning and Growth.</h5>
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`}/>
                    <h5 className='mx-4 my-0'>Results and Impact.</h5>
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`}/>
                    <h5 className='mx-4 my-0'>Prototyping and User Flows.</h5>
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`}/>
                    <h5 className='mx-4 my-0'>Interaction and Micro-interactions.</h5>
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <img src={`${process.env.PUBLIC_URL}/assets/ticks3.svg`}/>
                    <h5 className='mx-4 my-0'>User-Centered Approach.</h5>
                  </div>
                </div>
                <div className='col-md-5 s7-position-1'><img src={`${process.env.PUBLIC_URL}/assets/services-listing-new-banner1.png`} className='s7-position-2'/></div>
                <div className='col-12 hide-img-s7'><img src={`${process.env.PUBLIC_URL}/assets/services-listing-new-banner1.png`} className='img-fluid'/></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container pg1-s8' ref={sectionRef4}>
      <div className='row mt-5'>
        <div className='col-12 text-center'>
          <p className='pg1-section-1-text mt-5 s8-text'>PROJECTS</p>
          <h2 className='pg1-section1-heading s8-heading'>DIGITAL SHOWCASE</h2>
        </div>
        </div>
        <div className='row d-flexx justify-content-center swiper-3'>
        <div className='col-lg-8 col-md-10 col-12 d-flex justify-content-around flex-wrap'>
        <Swiper
      spaceBetween={20}
      slidesPerView={4}
      breakpoints={{
        320: {
          slidesPerView: 2,
        },
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }}
    >
      <SwiperSlide>  <div className='button-wrapper mt-2'>
    <button className='s8-button-1'>All</button>
  </div></SwiperSlide>
      <SwiperSlide>  <div className='button-wrapper mt-2'>
    <button className='s8-button-2'>Mobile App Design</button>
  </div></SwiperSlide>
      <SwiperSlide> <div className='button-wrapper mt-2'>
    <button className='s8-button-2'>Website Design</button>
  </div></SwiperSlide>
      <SwiperSlide>  <div className='button-wrapper mt-2'>
    <button className='s8-button-2'>App Development</button>
  </div></SwiperSlide>
    </Swiper>
</div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle6.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
    <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">CodenTire Website Design </h5>
      </div>
      <div className='col-2'>
        <a >
      <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn'/>
      </a>
      </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle7.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
    <h5 class="card-title">Edcademy Exam App Design</h5>
    </div>
    <div className='col-2'>
    <img  src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn1'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle8.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Leeway Portal Dashboard Design</h5>
    </div>
    <div className='col-2'>
    <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn2'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle9.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Conference & Events Website Design</h5>
    </div>
    <div className='col-2'>
    <img  src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn3'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle10.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Ecommerce Store Website Design</h5>
    </div>
    <div className='col-2'>
    <img  src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn4'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle11.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Bader Tech Website Design</h5>
    </div>
    <div className='col-2'>
    <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn5'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
        </div>
        <div className='row mt-5 pb-3'>
          <div className='col-3 mb-5 pagination'>
            <span className='ms-2 span1'>1</span>
            <span className='ms-2 span2'>2</span>
            <span className='ms-2 span2'>3</span>
            <span className='ms-2 span2'>4</span>
            <span className='ms-2 span2'>5</span>
          </div>
        </div>
        </div>
    </div>
    <div className='container-fluid footer1_main'>
    <div className='container px-lg-5 px-md-0'>
      <div className='row custom-padding-footer1 footer1-position2'>
        <div className='col-md-6 col-12 mt-5 mb-md-5 '>
          <h2 className="footer1-heading">Got a Project! Let’s Talk</h2>
          <p className="footer1-text">Let's Talk and bring your vision to life with a collaborative approach that ensures your project's success.</p>
          <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
            <div className='order-lg-1 order-2 mt-3 mt-lg-0'>
            <a href="https://w.app/hurerabhalli" target="_blank" className='footer1-btn'>
              Contact!
              </a>
            </div>
            <div className='d-flex mt-lg-0 mt-md-1 mt-2 order-lg-2 order-1'>
            <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf1.svg`} className='ms-lg-3'/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf2.svg`} className='ms-3'/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf3.svg`} className='ms-3'/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf4.svg`} className='ms-3'/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf5.svg`} className='ms-3'/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf6.svg`} className='ms-3'/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf7.svg`} className='ms-3'/>
      </a>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-12 footer1-position1 d-flex justify-content-center'>
        <img src={`${process.env.PUBLIC_URL}/assets/Hurerabhai.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1position.png`} className='img-fluid footer1-position3'/>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1positon2.png`} className='img-fluid footer1-position4' />
  </div>
  <div className='container-fluid footer2-bg' ref={sectionRef5}>
    <div className='container px-5'>
    <div className='row px-lg-5 pt-5'>
      <div className='col-md-6 col-12 order-md-1 order-2 mt-md-0 mt-5 mb-md-0 mb-5'>
        <div className='d-flex align-items-center'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img1.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Call:</p>
          <p className='m-0 footer2-text2'>+92-318-7842650</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img2.svg`}className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>E-mail:</p>
          <p className='m-0 footer2-text2'>hurerabhalli@gmail.com</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img3.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Location:</p>
          <p className='m-0 footer2-text2'>Johar Town, Lahore Pakistan</p>
        </div>
        </div>
      </div>
      <div className='col-md-6 col-12 order-md-2 order-1'>
        <div>
        <div className='ms-md-5 ms-0'>
        <h3 className='footer2-heading1'>Contact me:</h3>
        <p className='footer2-text3'>Let’s make something new, different and more meaningful or make thing more visual or conceptual!</p>
        <div>
          <button className='footer2-btn1' onClick={handleScroll4}>My Projects</button>
          <button className='footer2-btn2 ms-sm-3 ms-1' onClick={handleNavigate}>My Skills</button>
        </div>
        </div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2laptop.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div className='container-fluid footer3-bg'>
      <footer class="footer">
<div class="pt-5">
  <div class="container overflow-hidden">
    <div class="row align-items-sm-center">
      <div class="col-12 order-0 order-xl-0">
        <div class="footer-logo-wrapper text-center text-sm-start">
      <h2 className='text-center footer3-heading'>Hurera Bhalli<span className='footer3-mainheading'>.</span></h2>
      <p className='text-center  mx-auto footer3-text'>I work with passion of taking new challenges and creativity new ones in advertising sector. </p>
    <div className='d-flex justify-content-center gap-3'>
    <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social1.svg`}/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social2.svg`}/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social3.svg`}/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social4.svg`}/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social5.svg`}/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social6.svg`}/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social7.svg`}/>
      </a>
    </div>
        </div>
      </div>

      <div class="col-12 order-2 pt-3 pb-3">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={handleNavigate}>My Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={handleScroll4}>Projects</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleScroll5}>Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleNavigate5}>About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={handleNavigate}>Why Hire me?</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="py-3 border-top border-light-subtle w-75 mx-auto">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="footer-copyright-wrapper text-center">
        <p className='p-0 m-0'>Copyrights reserved @ hurera bhalli 2024</p>
        </div>
      </div>
    </div>
  </div>
</div>

</footer>
      </div>
    </div>
  );
};

export default HomePage;
