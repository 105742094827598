import React, { useEffect, useState,useRef } from 'react';
import { NavLink ,useNavigate} from 'react-router-dom';
import '../css/about.css'
function Projects() {
  const sectionRef5 = useRef(null);
    const textWithBreak = `"Web Designer Extraordinaire: Crafting Engaging and User<br/>-Friendly Digital Experiences"`;
    const textWithoutBreak = `"Web Designer Extraordinaire: Crafting Engaging and User-Friendly Digital Experiences"`;
  
    const [text, setText] = useState(textWithoutBreak);
    const navigate = useNavigate();
    const handleNavigate = () => {
      navigate('/resume');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const goHome = () => {
      navigate('/');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const handleNavigatenav = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const adjustBreakTag = () => {
        if (window.innerWidth >= 1200) {
          setText(textWithBreak);
        } else {
          setText(textWithoutBreak);
        }
      };
      useEffect(() => {
        adjustBreakTag(); // Adjust on initial load
    
        window.addEventListener('resize', adjustBreakTag);
        return () => window.removeEventListener('resize', adjustBreakTag);
      }, []);
      const handleScroll5 = () => {
        if (sectionRef5.current) {
          sectionRef5.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
  return (
<div>
<div className="hero-section-bg-pg2">
<div className='container'>
     <nav class="navbar navbar-expand-lg">
  <div class="container-fluid px-0">
    <a class="navbar-brand" href="#">Hurera<br/><span className='navbar-logo1'>Bhalli.</span></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#00E000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <desc>Download more icon variants from https://tabler-icons.io/i/menu-2</desc>
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="4" y1="6" x2="20" y2="6" />
  <line x1="4" y1="12" x2="20" y2="12" />
  <line x1="4" y1="18" x2="20" y2="18" />
</svg>

</span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll me-lg-5 me-0 gap-lg-4 gap-0" >
        <li class="nav-item mx-auto">
        <NavLink  onClick={handleNavigatenav} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/">
          Home
          </NavLink>
        </li>
        <li class="nav-item mx-auto">
        <a class="nav-link" href='#'>Projects</a>
        </li>
        <li class="nav-item mx-auto">
          <a class="nav-link" onClick={handleScroll5}>Contact</a>
        </li>
        <li class="nav-item mx-auto">
        <NavLink className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/about">
          About
          </NavLink>
        </li>
      </ul>
      <form class="d-flex justify-content-center" role="search">
        <button class="btn btn-outline-success" onClick={handleNavigate}>My Skills</button>
      </form>
    </div>
  </div>
</nav>
</div>
    <div className="container-fluid">
    <div className='container pt-5 hero-pg2-position11'>
  <div className='row pt-4'>
    <div className='col-lg-8 col-12 mt-lg-0 mt-5  hero-section-position mx-auto text-center'>
      <h2 className="hero-s1-rsum-h">About Me!</h2>
      <p className="hero-s1-rsum-p" dangerouslySetInnerHTML={{ __html: text }} />
      <div className='mt-2 d-flex justify-content-center gap-3 buttons-direction-pg2-hero'>
  <button className='hero-section1-btn22' onClick={handleNavigate}><img src={`${process.env.PUBLIC_URL}/assets/carbon_ibm-cloud-projects.svg`} className='me-2'/>Background?</button>
</div>
      <img src={`${process.env.PUBLIC_URL}/assets/herop1.png`} className='pg2-hero-img1-position' width='73' height='77' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop2.png`} className='pg2-hero-img2-position' width='113' height='98' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop3.png`} className='pg2-hero-img3-position' width='70' height='68' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop4.png`} className='pg2-hero-img4-position' width='150' height='129' />
    </div>
  </div>
  <div className='row mt-5 pb-5 pt-5'>
    <div className='col-12 pb-md-0 pb-5 d-flex gap-3 align-items-center justify-content-lg-center justify-content-md-start justify-content-center custom-padding-hero-pg2'>
    <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social1.svg`}/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social2.svg`}/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social3.svg`}/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social4.svg`}/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social5.svg`}/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social6.svg`}/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social7.svg`}/>
      </a>
    </div>
  </div>
</div>
    </div>
    <img src={`${process.env.PUBLIC_URL}/assets/navbarimg.png`} className='custom-img-position-heroSection'/>
<img src={`${process.env.PUBLIC_URL}/assets/navbarimg.png`} className='custom-img2-position-heroSection'/>
</div>
<div className='container-fluid p-0 about-section-1 position-relative'>
<div>
    <img src='assets/cutbg.png' className='img-fluid pt-5'/>
    </div>
    <div className="container position-relative about-section-1-part-1">
      <div className="linkedin-logo">
        <img src={`${process.env.PUBLIC_URL}/assets/Behance2.gif`} className='img-fluid gif-border' alt="LinkedIn Logo" />
      </div>
      <div className="main-image position-relative mt-5">
        <img src={`${process.env.PUBLIC_URL}/assets/about1.png`} className="img-fluid gif-border" alt="Main Image" />
        <div className="profile-top-left position-absolute">
          <img src={`${process.env.PUBLIC_URL}/assets/imgup.png`} className="img-fluid" alt="Top Left Image" />
        </div>
        <div className="profile-bottom-right position-absolute">
          <img src={`${process.env.PUBLIC_URL}/assets/imgdown.png`} className="img-fluid" alt="Bottom Right Image" />
        </div>
        <div className='position-absolute uuid-img23'>
      <img src='assets/uuid23.png' className='img-fluid'/>
    </div>
      </div>
    </div>
    <div className="container position-relative about-section-1-part-2">
      <div className="linkedin-logo">
        <img src={`${process.env.PUBLIC_URL}/assets/LinkdIn2.gif`} className='img-fluid gif-border ' alt="LinkedIn Logo" />
      </div>
      <div className="main-image position-relative mt-5">
        <img src={`${process.env.PUBLIC_URL}/assets/about2.png`} className="img-fluid gif-border" alt="Main Image" />
        <div className="profile-top-left position-absolute">
          <img src={`${process.env.PUBLIC_URL}/assets/imgup2.png`} className="img-fluid" alt="Top Left Image" />
        </div>
        <div className="profile-bottom-right position-absolute">
          <img src={`${process.env.PUBLIC_URL}/assets/imgdown2.png`} className="img-fluid" alt="Bottom Right Image" />
        </div>
        <div className='position-absolute uuid-img23'>
      <img src='assets/uuid24.png' className='img-fluid'/>
    </div>
      </div>
    </div>
    <div className='position-absolute uuid-img'>
      <img src='assets/uuid.png' className='img-fluid'/>
    </div>
    <div className='position-absolute uuid-img3'>
      <img src='assets/uuid-fdb5a3ba-a6dd-4bab-93b0-a4c1d3b183b8.svg' className='img-fluid'/>
    </div>
</div>
<div className='container-fluid p-0 about-section-1 position-relative'>
<div>
    <img src='assets/cutbg.png' className='img-fluid pt-5'/>
    </div>
    <div className="container position-relative about-section-1-part-3">
      <div className="linkedin-logo">
        <img src={`${process.env.PUBLIC_URL}/assets/Dribble2.gif`} className='img-fluid gif-border' alt="LinkedIn Logo" />
      </div>
      <div className="main-image position-relative mt-5">
        <img src={`${process.env.PUBLIC_URL}/assets/about3.png`} className="img-fluid gif-border" alt="Main Image" />
        <div className="profile-top-left position-absolute">
          <img src={`${process.env.PUBLIC_URL}/assets/imgup.png`} className="img-fluid" alt="Top Left Image" />
        </div>
        <div className="profile-bottom-right position-absolute">
          <img src={`${process.env.PUBLIC_URL}/assets/imgdown.png`} className="img-fluid" alt="Bottom Right Image" />
        </div>
        <div className='position-absolute uuid-img23'>
      <img src='assets/uuid23.png' className='img-fluid'/>
    </div>
      </div>
    </div>
   <div className='position-absolute uuid-img4'>
       <img src='assets/uuid-fdb5a3ba-a6dd-4bab-93b0-a4c1d3b183b8.svg' className='img-fluid'/>
    </div>
</div>
<div className='container-fluid footer1_main footer1-about'>
    <div className='container px-lg-5 px-md-0'>
      <div className='row footer1-position2 custom-padding-footer1'>
        <div className='col-md-6 col-12 mt-5 mb-md-5 '>
          <h2 className="footer1-heading">Got a Project! Let’s Talk</h2>
          <p className="footer1-text">Let's Talk and bring your vision to life with a collaborative approach that ensures your project's success.</p>
          <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
            <div className='order-lg-1 order-2 mt-3 mt-lg-0'>
            <a href="https://w.app/hurerabhalli" target="_blank" className='footer1-btn'>
              Contact!
              </a>
            </div>
            <div className='d-flex mt-lg-0 mt-md-1 mt-2 order-lg-2 order-1'>
            <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf1.svg`} className='ms-lg-3'/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf2.svg`} className='ms-3'/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf3.svg`} className='ms-3'/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf4.svg`} className='ms-3'/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf5.svg`} className='ms-3'/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf6.svg`} className='ms-3'/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf7.svg`} className='ms-3'/>
      </a>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-12 d-flex footer1-position1-about justify-content-center'>
        <img src={`${process.env.PUBLIC_URL}/assets/Hurerabhai.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1position.png`} className='img-fluid footer1-position3 footer1-position3-about'/>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1positon2.png`} className='img-fluid footer1-position4' />
  </div>
  <div className='container-fluid footer2-bg' ref={sectionRef5}>
    <div className='container px-5'>
    <div className='row px-lg-5 pt-5'>
      <div className='col-md-6 col-12 order-md-1 order-2 mt-md-0 mt-5 mb-md-0 mb-5'>
        <div className='d-flex align-items-center'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img1.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Call:</p>
          <p className='m-0 footer2-text2'>+92-318-7842650</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img2.svg`}className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>E-mail:</p>
          <p className='m-0 footer2-text2'>hurerabhalli@gmail.com</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img3.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Location:</p>
          <p className='m-0 footer2-text2'>Johar Town, Lahore Pakistan</p>
        </div>
        </div>
      </div>
      <div className='col-md-6 col-12 order-md-2 order-1'>
        <div>
        <div className='ms-md-5 ms-0'>
        <h3 className='footer2-heading1'>Contact me:</h3>
        <p className='footer2-text3'>Let’s make something new, different and more meaningful or make thing more visual or conceptual!</p>
        <div>
          <button className='footer2-btn1'>My Projects</button>
          <button className='footer2-btn2 ms-sm-3 ms-1' onClick={handleNavigate}>My Skills</button>
        </div>
        </div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2laptop.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div className='container-fluid footer3-bg'>
      <footer class="footer">
<div class="pt-5">
  <div class="container overflow-hidden">
    <div class="row align-items-sm-center">
      <div class="col-12 order-0 order-xl-0">
        <div class="footer-logo-wrapper text-center text-sm-start">
      <h2 className='text-center footer3-heading'>Hurera Bhalli<span className='footer3-mainheading'>.</span></h2>
      <p className='text-center  mx-auto footer3-text'>I work with passion of taking new challenges and creativity new ones in advertising sector. </p>
    <div className='d-flex justify-content-center gap-3'>
    <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social1.svg`}/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social2.svg`}/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social3.svg`}/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social4.svg`}/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social5.svg`}/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social6.svg`}/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social7.svg`}/>
      </a>
    </div>
        </div>
      </div>

      <div class="col-12 order-2 pt-3 pb-3">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={goHome}>Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleNavigate}>My Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" >Projects</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleScroll5}>Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" >About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={handleNavigate}>Why Hire me?</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="py-3 border-top border-light-subtle w-75 mx-auto">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="footer-copyright-wrapper text-center">
        <p className='p-0 m-0'>Copyrights reserved @ hurera bhalli 2024</p>
        </div>
      </div>
    </div>
  </div>
</div>

</footer>
      </div>
</div>
  );
}

export default Projects;
