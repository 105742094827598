import React, { useEffect, useState,useRef } from 'react';
import '../css/resumePage.css'
import { NavLink ,useNavigate} from 'react-router-dom';
import { Navigation} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
function ResumePage() {
  const navigate = useNavigate();
  const textWithBreak = `"Web Designer Extraordinaire: Crafting Engaging and User<br/>-Friendly Digital Experiences"`;
  const textWithoutBreak = `"Web Designer Extraordinaire: Crafting Engaging and User-Friendly Digital Experiences"`;

  const [text, setText] = useState(textWithoutBreak);

  const sectionRef = useRef(null);
  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const sectionRef1 = useRef(null);
  const handleScroll1 = () => {
    if (sectionRef1.current) {
      sectionRef1.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const sectionRef2 = useRef(null);
  const handleScroll2 = () => {
    if (sectionRef2.current) {
      sectionRef2.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const sectionRef6 = useRef(null);
  const handleScroll6 = () => {
    if (sectionRef6.current) {
      sectionRef6.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const sectionRef7 = useRef(null);
  const handleScroll7 = () => {
    if (sectionRef7.current) {
      sectionRef7.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleNavigate1 = () => {
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const navigateTOProjects =()=>{
    navigate('/projects');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const  handleNavigate3 =()=>{
    navigate('/about');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const adjustBreakTag = () => {
    if (window.innerWidth >= 1200) {
      setText(textWithBreak);
    } else {
      setText(textWithoutBreak);
    }
  };
  useEffect(() => {
    adjustBreakTag(); 

    window.addEventListener('resize', adjustBreakTag);
    return () => window.removeEventListener('resize', adjustBreakTag);
  }, []);
  const handleNavigate = () => {
    navigate('/resume');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleNavigatenav = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const handleNavigatenav1 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
<div>
<div className="hero-section-bg-pg2">
<div className='container'>
     <nav class="navbar navbar-expand-lg">
  <div class="container-fluid px-0">
    <a class="navbar-brand" href="#">Hurera<br/><span className='navbar-logo1'>Bhalli.</span></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-menu-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="#00E000" fill="none" stroke-linecap="round" stroke-linejoin="round">
  <desc>Download more icon variants from https://tabler-icons.io/i/menu-2</desc>
  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
  <line x1="4" y1="6" x2="20" y2="6" />
  <line x1="4" y1="12" x2="20" y2="12" />
  <line x1="4" y1="18" x2="20" y2="18" />
</svg>

</span>
    </button>
    <div class="collapse navbar-collapse" id="navbarScroll">
      <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll me-lg-5 me-0 gap-lg-4 gap-0" >
        <li class="nav-item mx-auto">
        <NavLink onClick={handleNavigatenav} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/">
          Home
          </NavLink>
        </li>
        <li class="nav-item mx-auto">
<a className='nav-link' onClick={handleScroll6}>Projects</a>
        </li>
        <li class="nav-item mx-auto">
          <a class="nav-link" onClick={handleScroll7}>Contact</a>
        </li>
        <li class="nav-item mx-auto">
        <NavLink onClick={handleNavigatenav1} className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')} to="/about">
          About
          </NavLink>
        </li>
      </ul>
      <div class="d-flex justify-content-center">
        <button class="btn btn-outline-success1">My Skills</button>
      </div>
    </div>
  </div>
</nav>
</div>
    <div className="container-fluid">
    <div className='container pt-5 hero-pg2-position11'>
  <div className='row'>
    <div className='col-lg-8 col-12 mt-lg-0 mt-5  hero-section-position mx-auto text-center'>
      <h2 className="hero-s1-rsum-h">My Resume</h2>
      <p className="hero-s1-rsum-p" dangerouslySetInnerHTML={{ __html: text }} />
      <div className='mt-2 d-flex justify-content-center gap-3 buttons-direction-pg2-hero'>
  <button className='hero-section1-btn1' onClick={handleScroll}>Why Hire Me!</button>
  <button className='hero-section1-btn2' onClick={handleScroll1}><img src={`${process.env.PUBLIC_URL}/assets/Vector.png`} className='me-2'/>Background?</button>
</div>
      <img src={`${process.env.PUBLIC_URL}/assets/herop1.png`} className='pg2-hero-img1-position' width='73' height='77' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop2.png`} className='pg2-hero-img2-position' width='113' height='98' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop3.png`} className='pg2-hero-img3-position' width='70' height='68' />
      <img src={`${process.env.PUBLIC_URL}/assets/herop4.png`} className='pg2-hero-img4-position' width='150' height='129' />
    </div>
  </div>
  <div className='row mt-5 pb-5 pt-5'>
    <div className='col-12 pb-md-0 pb-5 d-flex gap-3 align-items-center justify-content-lg-center justify-content-md-start justify-content-center custom-padding-hero-pg2'>
    <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social1.svg`}/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social2.svg`}/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social3.svg`}/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social4.svg`}/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social5.svg`}/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social6.svg`}/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social7.svg`}/>
      </a>
    </div>
  </div>
  <button className='hero-section-swiper2' onClick={handleScroll}>Swipe Down<img src={`${process.env.PUBLIC_URL}/assets/swiper.svg`} className='ms-2'/></button>
</div>
    </div>
    <img src={`${process.env.PUBLIC_URL}/assets/navbarimg.png`} className='custom-img-position-heroSection'/>
<img src={`${process.env.PUBLIC_URL}/assets/navbarimg.png`} className='custom-img2-position-heroSection'/>
</div>
<div className="container mt-5 mb-3 main-s2-position-pg2" ref={sectionRef}>
    <div className="row">
        <div className="col-lg-6 col-12">
            <h5 className="pg2-s1-title">ABOUT ME</h5>
            <h2 className="pg2-s1-heading">Why Hire Me:</h2>
            <p className="pg2-s1-text1">With 4 years of experience and over 130 successful web and mobile app projects, I deliver user-centric designs that elevate digital experiences.</p>
  <div>
  <div class="d-flex justify-content-between">
    <div class="">
      <h5 className="pg2-s1-text2">UI/UX Design</h5>
    </div>
    <div class="">
      <h5 className="pg2-s1-text2">85%</h5>
    </div>
  </div>
  <div>
    <img src="assets/line1.png" className="img-fluid"/>
  </div>
  </div>
  <div className="mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <h5 className="pg2-s1-text2">Graphics Design</h5>
    </div>
    <div class="">
      <h5 className="pg2-s1-text2">80%</h5>
    </div>
  </div>
  <div>
    <img src="assets/line2.png" className="img-fluid"/>
  </div>
  </div>
  <div className="mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <h5 className="pg2-s1-text2">Web Design</h5>
    </div>
    <div class="">
      <h5 className="pg2-s1-text2">90%</h5>
    </div>
  </div>
  <div>
    <img src="assets/line3.png" className="img-fluid"/>
  </div>
  </div>
  <div className="mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <h5 className="pg2-s1-text2">AppDesign</h5>
    </div>
    <div class="">
      <h5 className="pg2-s1-text2">95%</h5>
    </div>
  </div>
  <div>
    <img src="assets/line4.png" className="img-fluid"/>
  </div>
  </div>
  <div className="mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <h5 className="pg2-s1-text2">Landing Pages Design</h5>
    </div>
    <div class="">
      <h5 className="pg2-s1-text2">90%</h5>
    </div>
  </div>
  <div>
    <img src="assets/line5.png" className="img-fluid"/>
  </div>
  </div>
  <div className="mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <h5 className="pg2-s1-text2">Figma</h5>
    </div>
    <div class="">
      <h5 className="pg2-s1-text2">90%</h5>
    </div>
  </div>
  <div>
    <img src="assets/line5.png" className="img-fluid"/>
  </div>
  </div>
  <div className="mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <h5 className="pg2-s1-text2">Adobe Illustrator</h5>
    </div>
    <div class="">
      <h5 className="pg2-s1-text2">90%</h5>
    </div>
  </div>
  <div>
    <img src="assets/line5.png" className="img-fluid"/>
  </div>
  </div>
        </div>
        <div className="col-lg-6 col-12 s1-position-img1-pg2">
            <img src="assets/Team-Work-Together1.png" className="img-fluid s1-position-img2-pg2"/>
        </div>
    </div>
    <button className='hero-section-swiper1' onClick={handleScroll1}>Swipe Down<img src={`${process.env.PUBLIC_URL}/assets/swiper.svg`} className='ms-2'/></button>
</div>
<div className="container" ref={sectionRef1}>
    <div className="row">
        <div className="col-12 text-center">
            <hr className="horizontal-line"/>
        <h5 className="pg2-s1-title mt-5 text-center">EXPERIENCE</h5>
            <h2 className="pg2-s1-heading">Work Experience:</h2>
        </div>
    </div>
</div>
<div className='container position-relative'>
<section id="conference-timeline">
  <div>
    <div className="conference-center-line"></div>
    <div className="conference-timeline-content">
      <div className="timeline-article">
        <div className="content-left-container">
          <div className="content-left content1-s2">
          <ul className="m-0">
                  <li>Worked on their official Website Design with the internees and developers to create a functional website.</li>
                  <li>Designed multiple websites for different international clients, Bader Website, Leeway Portal.</li>
                  <li>For the side work I've also increased their social media presence by daily posting on multiple platforms.</li>
                </ul>
          </div>
        </div>
        <div className="content-right-container">
          <div className="content-right content2-s2">
          <h2 className="s2-content2-heading-pg2">Index World</h2>
                <h6 className="s2-content2-heading1-pg2">UI & Graphics Designer</h6>
                <p className="s2-content2-text1-pg2">Team Lead</p>
                <p className="s2-content2-text2-pg2">September 2021 - March 2022</p>
          </div>
        </div>
        <div className="meta-date d-flex justify-content-center align-items-center">
          <span className="date">01</span>
        </div>
      </div>
      <div className="timeline-article">
        <div className="content-left-container d-flex justify-content-end">
          <div className="content-left content3-s2">
          <h2 className="s2-content2-heading-pg2">Fiverr</h2>
                <h6 className="s2-content2-heading1-pg2">UI & Graphics Designer</h6>
                <p className="s2-content2-text2-pg2">March 2022 - Present</p>
          </div>
        </div>
        <div className="content-right-container">
          <div className="content-right content3-s3">
                <p className="s2-content2-text2-pg2 m-0">As a dedicated UI/UX designer with over two years of experience on Fiverr, I have completed more than 120 projects in web and mobile app design, consistently maintaining a 4.9 rating. My commitment to delivering high-quality design has earned me over 150 satisfied clients, including both Fiverr and direct engagements. Specializing in intuitive, user-centered designs, I excel in transforming client visions into exceptional digital experiences that captivate and engage audiences. </p>
          </div>
        </div>
        <div className="meta-date d-flex justify-content-center align-items-center">
          <span className="date">02</span>
        </div>
      </div>
      <div className="timeline-article">
        <div className="content-left-container">
          <div className="content-left content4-s3">
                      <ul className="m-0">
                  <li>Learned basics of UI and UX design to design creative and easy-to-use interfaces for website and apps.</li>
                  <li>Completed the certification of Google UX professional.</li>
                  <li>Designed my FYP App interface.</li>
                </ul>
          </div>
        </div>
        <div className="content-right-container">
          <div className="content-right content6-s3">
          <h2 className="s2-content2-heading-pg2">Source Code Solution</h2>
                <h6 className="s2-content2-heading1-pg2">UI Designer Internee</h6>
                <p className="s2-content2-text2-pg2">June 2021 - August 2021</p>
          </div>
        </div>
        <div className="meta-date d-flex justify-content-center align-items-center">
          <span className="date">03</span>
        </div>
      </div>
    </div>
    </div>
  </section>
  <button className='hero-section-swiper3 d-lg-block d-none' onClick={handleScroll2}>Swipe Down<img src={`${process.env.PUBLIC_URL}/assets/swiper.svg`} className='ms-2'/></button>
  </div>
<div className="container conference-timeline2">
  <div className="row mt-5">
    <div className="col-12">
      <div className="text-center d-flex justify-content-center">
      <div className="meta-date1 d-flex justify-content-center align-items-center">
          <span className="date">01</span>
        </div>
      </div>
    </div>
  </div>
  <div className="row mt-5 d-flex text-center justify-content-center text-align-center">
    <div className="col-md-8 col-12">
    <div className="">
          <h2 className="s2-content2-heading-pg2">Index World</h2>
                <h6 className="s2-content2-heading1-pg2">UI & Graphics Designer</h6>
                <p className="s2-content2-text1-pg2">Team Lead</p>
                <p className="s2-content2-text2-pg2">September 2021 - March 2022</p>
          </div>
    </div>
  </div>
  <div className="row d-flex justify-content-center">
  <div className="col-lg-8 col-md-8">
    <div className="content1-s7">
          <ul className="m-0">
                  <li>Worked on their official Website Design with the internees and developers to create a functional website.</li>
                  <li>Designed multiple websites for different international clients, Bader Website, Leeway Portal.</li>
                  <li>For the side work I've also increased their social media presence by daily posting on multiple platforms.</li>
                </ul>
          </div>
    </div>
  </div>
  <div className="row mt-5">
    <div className="col-12">
      <div className="text-center d-flex justify-content-center">
      <div className="meta-date1 d-flex justify-content-center align-items-center">
          <span className="date">02</span>
        </div>
      </div>
    </div>
  </div>
  <div className="row mt-5 d-flex text-center justify-content-center text-align-center">
    <div className="col-md-8 col-12">
    <div className="">
    <h2 className="s2-content2-heading-pg2">Fiverr</h2>
                <h6 className="s2-content2-heading1-pg2">UI & Graphics Designer</h6>
                <p className="s2-content2-text2-pg2">March 2022 - Present</p>
          </div>
    </div>
  </div>
  <div className="row d-flex justify-content-center">
  <div className="col-lg-8 col-md-8">
  <div className="content2-s7">
                <p className="s2-content2-text2-pg3 m-0">As a dedicated UI/UX designer with over two years of experience on Fiverr, I have completed more than 120 projects in web and mobile app design, consistently maintaining a 4.9 rating. My commitment to delivering high-quality design has earned me over 150 satisfied clients, including both Fiverr and direct engagements. Specializing in intuitive, user-centered designs, I excel in transforming client visions into exceptional digital experiences that captivate and engage audiences. </p>
          </div>
    </div>
  </div>
  <div className="row mt-5">
    <div className="col-12">
      <div className="text-center d-flex justify-content-center">
      <div className="meta-date1 d-flex justify-content-center align-items-center">
          <span className="date">03</span>
        </div>
      </div>
    </div>
  </div>
  <div className="row mt-5 d-flex text-center justify-content-center text-align-center">
    <div className="col-md-8 col-12">
    <div className="">
    <h2 className="s2-content2-heading-pg2">Source Code Solutions</h2>
                <h6 className="s2-content2-heading1-pg2">UI Designer Internee</h6>
                <p className="s2-content2-text2-pg2">June 2021 - August 2021</p>
          </div>
    </div>
  </div>
  <div className="row pb-5 d-flex justify-content-center">
  <div className="col-lg-8 pb-4 col-md-8">
  <div className="content2-s7">
  <ul className="m-0">
                  <li>Learned basics of UI and UX design to design creative and easy-to-use interfaces for website and apps.</li>
                  <li>Completed the certification of Google UX professional.</li>
                  <li>Designed my FYP App interface.</li>
                </ul>
          </div>
    </div>
  </div>
  <button className='hero-section-swiper4' onClick={handleScroll2}>Swipe Down<img src={`${process.env.PUBLIC_URL}/assets/swiper.svg`} className='ms-2'/></button>
</div>
<div className="container-fluid pg2-section4-bg" ref={sectionRef2}>
<div className="row">
  <div className="col-12">
  <h5 className="pg2-s1-title mt-5 text-center text-white">EDUCATION</h5>
    <h2 className="pg2-s1-heading text-center text-white s4-h-pg2">Educational Background:</h2>
  <div class="timeline mt-5">
  <div class="container1 left">
    <div className='icon'>01</div>
    <div class="content">
      <div className="timeline2-bg">
      <h2 className="timeline2-heading">UMT Lahore</h2>
      <h5 className="timeline2-heading2">B.Sc - Information Technology</h5>
      <div className="d-flex justify-content-between">
        <div>
          <p className="timeline2-text">2018 - 2022</p>
        </div>
        <div>
          <p className="timeline2-text">CGPA: 3.44</p>
        </div>
      </div>
      <h4 className="timeline2-heading3 mt-2">Courses:</h4>
      <ul className="m-0">
        <li>Mobile Application Development.</li>
        <li>Web Systems & Technologies.</li>
        <li>Computer Programming.</li>
        <li>Software Quality Assurance.</li>
      </ul>
      </div>
    </div>
  </div>
  <div class="container1 right">
  <div className='icon'>02</div>
    <div class="content">
    <div className="timeline2-bg">
      <h2 className="timeline2-heading">Punjab College Lahore</h2>
      <h5 className="timeline2-heading2">I.Cs - Computer Science </h5>
      <div className="d-flex justify-content-between">
        <div>
          <p className="timeline2-text">2015 - 2017</p>
        </div>
        <div>
          <p className="timeline2-text">Grade: A+</p>
        </div>
      </div>
      <h4 className="timeline2-heading3 mt-2">Courses:</h4>
      <ul className="m-0">
        <li>Computer.</li>
        <li>Physics</li>
        <li>Math.</li>
      </ul>
      </div>
    </div>
  </div>
  <div class="container1 left ">
  <div className='icon'>03</div>
    <div class="content">
    <div className="timeline2-bg">
      <h2 className="timeline2-heading">Beaconhouse School</h2>
      <h5 className="timeline2-heading2">Matriculation - Computer Science</h5>
      <div className="d-flex justify-content-between">
        <div>
          <p className="timeline2-text">2014 - 2015</p>
        </div>
      </div>
      <h4 className="timeline2-heading3 mt-2">Courses:</h4>
      <ul className="m-0">
        <li>Computer</li>
        <li>Physics.</li>
        <li>Chemistry.</li>
      </ul>
      </div>
    </div>
  </div>
</div>
<div className="timeline3 mt-5">
  <div className="row d-flex justify-content-center">
    <div className="col-md-8 col-12">
      <div className="d-flex justify-content-center">
      <div className="meta-date2 d-flex justify-content-center align-items-center">
          <span className="date">01</span>
        </div>
        </div>
    <div className="timeline2-bg mt-5">
      <h2 className="timeline2-heading">UMT Lahore</h2>
      <h5 className="timeline2-heading2">B.Sc - Information Technology</h5>
      <div className="d-flex justify-content-between">
        <div>
          <p className="timeline2-text">2018 - 2022</p>
        </div>
        <div>
          <p className="timeline2-text">CGPA: 3.44</p>
        </div>
      </div>
      <h4 className="timeline2-heading3 mt-2">Courses:</h4>
      <ul className="m-0">
        <li>Mobile Application Development.</li>
        <li>Web Systems & Technologies.</li>
        <li>Computer Programming.</li>
        <li>Software Quality Assurance.</li>
      </ul>
      </div>
    </div>
  </div>
  <div className="row d-flex justify-content-center mt-5">
    <div className="col-md-8 col-12 ">
      <div className="d-flex justify-content-center">
      <div className="meta-date2 d-flex justify-content-center align-items-center">
          <span className="date">02</span>
        </div>
        </div>
        <div className="timeline2-bg mt-5">
      <h2 className="timeline2-heading">Punjab College Lahore</h2>
      <h5 className="timeline2-heading2">I.Cs - Computer Science </h5>
      <div className="d-flex justify-content-between">
        <div>
          <p className="timeline2-text">2015 - 2017</p>
        </div>
        <div>
          <p className="timeline2-text">Grade: A+</p>
        </div>
      </div>
      <h4 className="timeline2-heading3 mt-2">Courses:</h4>
      <ul className="m-0">
        <li>Computer.</li>
        <li>Physics</li>
        <li>Math.</li>
      </ul>
      </div>
    </div>
  </div>
  <div className="row d-flex justify-content-center mt-5">
    <div className="col-md-8 col-12">
      <div className="d-flex justify-content-center">
      <div className="meta-date2 d-flex justify-content-center align-items-center">
          <span className="date">02</span>
        </div>
        </div>
        <div className="timeline2-bg mt-5 mb-5">
      <h2 className="timeline2-heading">Beaconhouse School</h2>
      <h5 className="timeline2-heading2">Matriculation - Computer Science</h5>
      <div className="d-flex justify-content-between">
        <div>
          <p className="timeline2-text">2014 - 2015</p>
        </div>
      </div>
      <h4 className="timeline2-heading3 mt-2">Courses:</h4>
      <ul className="m-0">
        <li>Computer</li>
        <li>Physics.</li>
        <li>Chemistry.</li>
      </ul>
      </div>
    </div>
  </div>
</div>
  </div>
</div>
</div>
<div className='container-fluid pg1-s8-bg12'>
  <div className="container" ref={sectionRef6}>
      <div className='row'>
        <div className='col-12 text-center'>
          <p className='pg1-section-1-text mt-5 s8-text'>PROJECTS</p>
          <h2 className='pg1-section1-heading s8-heading'>DIGITAL SHOWCASE</h2>
        </div>
        </div>
        <div className='row d-flexx justify-content-center swiper-3'>
        <div className='col-lg-8 col-md-10 col-12 d-flex justify-content-around flex-wrap'>
        <Swiper
      spaceBetween={20}
      slidesPerView={4}
      breakpoints={{
        320: {
          slidesPerView: 2,
        },
        500: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 4,
        },
      }}
    >
      <SwiperSlide>  <div className='button-wrapper mt-2'>
    <button className='s8-button-1'>All</button>
  </div></SwiperSlide>
      <SwiperSlide>  <div className='button-wrapper mt-2'>
    <button className='s8-button-2'>Mobile App Design</button>
  </div></SwiperSlide>
      <SwiperSlide> <div className='button-wrapper mt-2'>
    <button className='s8-button-2'>Website Design</button>
  </div></SwiperSlide>
      <SwiperSlide>  <div className='button-wrapper mt-2'>
    <button className='s8-button-2'>App Development</button>
  </div></SwiperSlide>
    </Swiper>
</div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle6.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
    <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">CodenTire Website Design </h5>
      </div>
      <div className='col-2'>
        <a >
      <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn'/>
      </a>
      </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle7.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
    <h5 class="card-title">Edcademy Exam App Design</h5>
    </div>
    <div className='col-2'>
    <img  src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn1'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle8.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Leeway Portal Dashboard Design</h5>
    </div>
    <div className='col-2'>
    <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn2'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle9.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Conference & Events Website Design</h5>
    </div>
    <div className='col-2'>
    <img  src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn3'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle10.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Ecommerce Store Website Design</h5>
    </div>
    <div className='col-2'>
    <img  src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn4'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
          <div className='col-lg-4 col-md-6 col-12 mt-3'>
          <div class="card" onClick={navigateTOProjects}>
  <img src={`${process.env.PUBLIC_URL}/assets/Rectangle11.png`} class="card-img-top" alt="..."/>
  <div class="card-body">
  <div className='row'>
      <div className='col-10'>
      <h5 class="card-title">Bader Tech Website Design</h5>
    </div>
    <div className='col-2'>
    <img src={`${process.env.PUBLIC_URL}/assets/cardbtn.svg`} className='cardbtn5'/>
    </div>
    </div>
    <p class="card-text">Website/Landing Page</p>
  </div>
</div>
          </div>
        </div>
        <div className='row mt-5 pb-3'>
          <div className='col-3 mb-5 pagination'>
            <span className='ms-2 span1'>1</span>
            <span className='ms-2 span2'>2</span>
            <span className='ms-2 span2'>3</span>
            <span className='ms-2 span2'>4</span>
            <span className='ms-2 span2'>5</span>
          </div>
        </div>
        </div>
        </div>
        <div className='container-fluid footer1_main'>
    <div className='container px-lg-5 px-md-0'>
      <div className='row footer1-position2 custom-padding-footer1'>
        <div className='col-md-6 col-12 mt-5 mb-md-5 '>
          <h2 className="footer1-heading">Got a Project! Let’s Talk</h2>
          <p className="footer1-text">Let's Talk and bring your vision to life with a collaborative approach that ensures your project's success.</p>
          <div className='d-flex align-items-lg-center flex-column flex-lg-row'>
            <div className='order-lg-1 order-2 mt-3 mt-lg-0'>
            <a href="https://w.app/hurerabhalli" target="_blank" className='footer1-btn'>
              Contact!
              </a>
            </div>
            <div className='d-flex mt-lg-0 mt-md-1 mt-2 order-lg-2 order-1'>
            <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf1.svg`} className='ms-lg-3'/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf2.svg`} className='ms-3'/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf3.svg`} className='ms-3'/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf4.svg`} className='ms-3'/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf5.svg`} className='ms-3'/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf6.svg`} className='ms-3'/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/socialf7.svg`} className='ms-3'/>
      </a>
            </div>
          </div>
        </div>
        <div className='col-md-6 col-12 footer1-position1 d-flex justify-content-center'>
        <img src={`${process.env.PUBLIC_URL}/assets/Hurerabhai.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1position.png`} className='img-fluid footer1-position3'/>
    <img src={`${process.env.PUBLIC_URL}/assets/footer1positon2.png`} className='img-fluid footer1-position4' />
  </div>
  <div className='container-fluid footer2-bg' ref={sectionRef7}>
    <div className='container px-5'>
    <div className='row px-lg-5 pt-5'>
      <div className='col-md-6 col-12 order-md-1 order-2 mt-md-0 mt-5 mb-md-0 mb-5'>
        <div className='d-flex align-items-center'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img1.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Call:</p>
          <p className='m-0 footer2-text2'>+92-318-7842650</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img2.svg`}className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>E-mail:</p>
          <p className='m-0 footer2-text2'>hurerabhalli@gmail.com</p>
        </div>
        </div>
        <div className='d-flex align-items-center mt-md-5 mt-3'>
        <div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2img3.svg`} className='footer2-bg-img' height="86" width="86"/>
        </div>
        <div className='ms-3'>
          <p className='m-0 footer2-text1'>Location:</p>
          <p className='m-0 footer2-text2'>Johar Town, Lahore Pakistan</p>
        </div>
        </div>
      </div>
      <div className='col-md-6 col-12 order-md-2 order-1'>
        <div>
        <div className='ms-md-5 ms-0'>
        <h3 className='footer2-heading1'>Contact me:</h3>
        <p className='footer2-text3'>Let’s make something new, different and more meaningful or make thing more visual or conceptual!</p>
        <div>
          <button className='footer2-btn1' onClick={handleScroll6}>My Projects</button>
          <button className='footer2-btn2 ms-sm-3 ms-1' onClick={handleNavigate1}>Main Page</button>
        </div>
        </div>
        <img src={`${process.env.PUBLIC_URL}/assets/footer2laptop.png`} className='img-fluid'/>
        </div>
      </div>
    </div>
    </div>
  </div>
  <div className='container-fluid footer3-bg'>
      <footer class="footer">
<div class="pt-5">
  <div class="container overflow-hidden">
    <div class="row align-items-sm-center">
      <div class="col-12 order-0 order-xl-0">
        <div class="footer-logo-wrapper text-center text-sm-start">
      <h2 className='text-center footer3-heading'>Hurera Bhalli<span className='footer3-mainheading'>.</span></h2>
      <p className='text-center  mx-auto footer3-text'>I work with passion of taking new challenges and creativity new ones in advertising sector. </p>
    <div className='d-flex justify-content-center gap-3'>
    <a href="https://www.facebook.com/hurera.bhalli/?_rdr" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social1.svg`}/>
      </a>
      <a href="https://www.linkedin.com/in/hurera-bhalli/" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social2.svg`}/>
      </a>
      <a href="https://www.behance.net/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social3.svg`}/>
      </a>
      <a href="https://dribbble.com/Hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social4.svg`}/>
      </a>
      <a href="https://www.instagram.com/hurerabhalli/?" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social5.svg`}/>
      </a>
      <a href="https://join.skype.com/invite/xFbaeBeW0U7v" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social6.svg`}/>
      </a>
      <a href="https://w.app/hurerabhalli" target="_blank">
      <img src={`${process.env.PUBLIC_URL}/assets/social7.svg`}/>
      </a>
    </div>
        </div>
      </div>

      <div class="col-12 order-2 pt-3 pb-3">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" onClick={handleNavigate1}>Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" >My Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleScroll6}>Projects</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleScroll7} >Contact</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3"  onClick={handleNavigate3}>About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link link-secondary px-2 px-md-3" >Why Hire me?</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="py-3 border-top border-light-subtle w-75 mx-auto">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="footer-copyright-wrapper text-center">
        <p className='p-0 m-0'>Copyrights reserved @ hurera bhalli 2024</p>
        </div>
      </div>
    </div>
  </div>
</div>

</footer>
      </div>
</div>
  );
}

export default ResumePage;
